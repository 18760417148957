// @flow
import React, { useMemo, useState } from "react";
import styled from "react-emotion";
import history from "src/shared/history";
import notification from "antd/lib/notification";
import env from "src/shared/env";
import IconButton from '@mui/material/IconButton';
import ButtonUnstyled, { buttonUnstyledClasses }  from '@mui/base/ButtonUnstyled';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from "@mui/material";

const Button = styled(ButtonUnstyled)`
  font-family: Centra No2 Medium;
  font-weight: bold;
  letter-spacing: 1px;
  marginTop: 32px;
  text-align: center;
  width: 100%;
  font-size: 0.875rem;
  background-color: #194F5B;
  padding: 12px 24px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  position: relative;
  left: 8px;
  &:hover {
    background-color: #15424d;
  }


  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type Props = {
  location: {
    search: string,
  },
};

const Heading = styled.h1({
  fontFamily: 'Centra No2 Medium',
  fontSize: 20,
  fontWeight: '700',
  textAlign: 'center',
  color: '#194F5B'
});

const P = styled.p({
  fontFamily: 'Centra No2',
  fontSize: 14,
  fontWeight: '400',
  textAlign: 'center',
  width: 343,
  margin: '0 auto',
  color: '#194F5B'
})

const InputAdornmentText = styled.p({
  fontFamily: 'Centra No2',
  fontSize: 14,
  fontWeight: '400',
  textAlign: 'right',
  color: '#A2A9AD',
  '&:hover': {
    cursor: 'select'
  }
})


const PasswordInput = ({ password, setPassword, isPasswordValid }) => {
  const [showPassword, setShowPassword] = useState(false)
  return <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
    <InputLabel htmlFor="standard-adornment-password">Create password</InputLabel>
    <Input
      error={!isPasswordValid}
      id="standard-adornment-password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton  onClick={() => setShowPassword( show => !show)} onMouseDown={(event) => event.preventDefault()}>
            <InputAdornmentText>{showPassword ? 'Hide' : 'Show'}</InputAdornmentText>
          </IconButton>
        </InputAdornment>
      }
    />
    {!isPasswordValid ? <FormHelperText error>Password does not meet requirements.</FormHelperText> : null}
  </FormControl>
}




const ResetPassword = ({ location }: Props) => {
  const [password, setPassword] = useState('')
  const isPasswordValid = useMemo(() => {
    if (!password.length) {
      return true
    }
    if (password.length < 8 || password.toUpperCase() === password || password.toLowerCase() === password || !(/\d/.test(password))) {
      return false;
    } else {
      return true
    }
  }, [password])
  const token = location.search.replace("?reset_password_token=", "");

  const onSubmit = async ()  => {

    const data = JSON.stringify({
      password,
      password_confirmation: password,
      reset_password_token: token,
    });

    const result = await fetch(`${env().apiURL}/api/v1/users/password`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: data,
    });

    const json = await result.json();

    if (result.status >= 200 && result.status <= 299) {
      history.push("/password-reset");
    } else {
      notification.error({
        message: "Error",
        description: (json.error && json.error[0]) || "Is the link still valid?",
      });
    }
  };
  
  return (
    <div style={{ width: 375, margin: '90px auto', justifyContent: 'center'}}>
      <Heading>Set your password</Heading>
      <P>Must include at least 1 lowercase letter, 1 uppercase letter, and 1 number.</P>
      <PasswordInput isPasswordValid={isPasswordValid} password={password} setPassword={setPassword} />
      <div style={{ marginTop: 25 }}></div>
      <Button disabled={!isPasswordValid || !password.length} onClick={onSubmit} disableFocusRipple disableRipple style={{ backgrondColor: '#194F5B' }} variant="contained" disableElevation>
        SET PASSWORD
      </Button>
    </div>
  );
};

export default ResetPassword
