// @flow
import React from "react";
import styled from "react-emotion";
import { successIcon } from "./successIcon";

const Heading = styled.h1({
  fontFamily: 'Centra No2 Medium',
  fontSize: 20,
  fontWeight: '700',
  textAlign: 'center',
  color: '#194F5B'
});

const P = styled.p({
  fontFamily: 'Centra No2',
  fontSize: 14,
  fontWeight: '400',
  textAlign: 'center',
  color: '#194F5B',
  width: 287,
  margin: '0 auto'
})

const Done = () => {
  return (
    <div style={{ width: 375, margin: '90px auto', justifyContent: 'center'}}>
      <Heading>Password set!</Heading>
      <P>Nice job. Log in to the app with your new password.</P>
      <div style={{ width: 'fit-content', margin: '20px auto' }}><img alt="" src={`data:image/svg+xml;utf8,${encodeURIComponent(successIcon)}`}/></div>
    </div>
  );
};

export default Done;
