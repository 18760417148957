// @flow
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import ResetPassword from "./scenes/ResetPassword/ResetPassword";
import ResetPasswordDone from "./scenes/ResetPassword/Done";
import history from "./shared/history";

const App = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/password-reset" component={ResetPasswordDone} />
      <Route path="/" component={() => null} />
    </Switch>
  </Router>
);

export default App;
