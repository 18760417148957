export const successIcon = `<svg width="131" height="99" viewBox="0 0 131 99" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.1268 21.7075C33.1214 22.1764 34.9171 22.1909 37.9273 21.7771C41.3596 21.2951 45.8146 20.6645 47.8541 20.3366C61.8619 18.0808 65.0085 33.2248 62.6901 45.8064C60.3453 58.5307 52.2318 81.269 44.8772 81.2008C38.7661 81.1443 38.4674 75.2848 38.5027 72.2357C38.5278 69.1886 37.7111 62.2598 33.6616 62.221C29.6121 62.1822 28.6657 69.1011 28.6406 72.1483C28.6156 75.1954 28.2025 81.05 22.1035 81.0018C14.7489 80.9337 7.05072 58.0636 4.93282 45.3035C2.83145 32.6887 5.13295 17.4601 20.2231 20.0906C22.2639 20.4515 26.7055 21.1697 30.1268 21.7075Z" fill="white" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M89.4671 22.2214C92.4617 22.6904 94.2575 22.7048 97.2676 22.291C100.7 21.809 105.155 21.1784 107.194 20.8505C121.202 18.5948 124.349 33.7387 122.03 46.3203C119.686 59.0446 111.572 81.7829 104.217 81.7147C98.1064 81.6582 97.8078 75.7987 97.8431 72.7496C97.8681 69.7025 97.0515 62.7737 93.002 62.7349C88.9525 62.6961 88.006 69.6151 87.981 72.6622C87.9559 75.7093 87.5428 81.5639 81.4439 81.5157C74.0893 81.4476 66.3911 58.5775 64.2732 45.8174C62.182 33.2007 64.4835 17.9721 79.5737 20.6026C81.6042 20.9655 86.0459 21.6836 89.4671 22.2214Z" fill="white" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.3953 32.199C47.1561 33.3037 46.1655 34.1354 44.9957 34.1206C44.5806 34.1134 44.2014 34.0146 43.8601 33.8345" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M56.4783 29.1352C56.0989 30.0011 55.2259 30.6094 54.2175 30.6069C53.6428 30.5976 53.1074 30.4008 52.6926 30.0542" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M81.2734 31.4058C81.4922 32.5204 82.4653 33.3563 83.6454 33.3692C84.0605 33.3764 84.4443 33.2729 84.784 33.1035" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M73.0503 28.3938C73.4153 29.2691 74.2746 29.8931 75.2829 29.8956C75.8576 29.9049 76.394 29.7093 76.8094 29.3773" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M71.769 35.958C71.769 35.958 73.7476 40.1893 79.3464 39.2072" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M79.3622 37.593C79.3622 37.593 78.5048 39.3645 80.547 40.2445" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M8.92813 41.9815C9.48767 48.386 10.8135 54.6897 12.8949 60.7781C13.9416 63.8264 15.1755 66.7974 16.5863 69.693C17.7202 72.0148 18.913 74.3681 20.6821 76.2851C21.3055 76.9531 22.3851 76.0306 21.7598 75.3523C20.0169 73.4622 18.8728 71.1423 17.7549 68.8493C16.38 66.0319 15.18 63.1287 14.169 60.1585C12.1414 54.1872 10.8776 47.9886 10.3327 41.7192C10.2463 40.8025 8.8437 41.075 8.92813 41.9815Z" fill="#D6EBF6"/>
<path d="M67.7153 42.4936C68.2748 48.898 69.6006 55.2018 71.682 61.2902C72.7288 64.3385 73.9627 67.3095 75.3735 70.2051C76.5073 72.5269 77.7001 74.8802 79.4692 76.7972C80.0926 77.4652 81.1723 76.5427 80.547 75.8644C78.8041 73.9743 77.66 71.6544 76.5421 69.3614C75.1671 66.5439 73.9671 63.6408 72.9562 60.6706C70.9202 54.7115 69.6564 48.5128 69.1115 42.2435C69.0354 41.3248 67.6308 41.5871 67.7153 42.4936Z" fill="#D6EBF6"/>
<path d="M55.4184 37.3797C55.4184 37.3797 54.3064 40.174 49.8379 39.3124" stroke="#1B505C" stroke-width="1.10993" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>`;
