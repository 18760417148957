// @flow
type Env = {
  apiURL: string,
};

const env = (): Env => {
  let apiURL = process.env.REACT_APP_API_HOST;
  if (!apiURL) {
    console.error("environment variable REACT_APP_API_HOST not set, defaulting to production");
    apiURL = "https://api.uniformteeth.com";
  }

  return {
    apiURL,
  };
};

export default env;
